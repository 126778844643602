import React from 'react';
import {
  actionButton,
  approvedIcon,
  cross,
  redCross,
  tickIcon,
} from '../../../assets';
import { CustomIcon } from '../../customAssets';
import CustomText from '../CustomText';
import useCustomTable from './use-custom-table.hook';
import CustomPagination from '../custom-pagination';
import { Tooltip } from '@material-tailwind/react';

function CustomTable({
  header,
  rows,
  handleActionClick,
  toggleActionMenu,
  openViewClaimModal,
  menu,
  icons,
  actionMenuRef,
  table,
  noPaginate,
  minHeight = 'min-h-[400px]'
}) {
  const {
    currentItems,
    renderPaginationNumbers,
    handlePrevPage,
    handleNextPage,
    disableNext,
    disablePrevious,
  } = useCustomTable(rows, table);
  const wideCols = ['nickName', 'address', 'from', 'email', 'recipient'];

  const suspendedGif = (
    <svg width='80' height='28' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='36' cy='16' rx='36' ry='8' fill='red' />

      <text
        x='48'
        y='16'
        dominant-baseline='middle'
        text-anchor='middle'
        font-family='Arial'
        font-size='10'
        fill='white'
      >
        <tspan x='36' dy='0'>
          Suspended
        </tspan>
        <animate
          attributeName='opacity'
          values='0;1;0'
          dur='1.5s'
          repeatCount='indefinite'
        />{' '}
        {/* rotate only */}
        {/* <animateTransform
          attributeName='transform'
          attributeType='XML'
          type='rotate'
          from='0 48 20'
          to='10 48 20'
          dur='2s'
          repeatCount='indefinite'
        /> */}
      </text>
    </svg>
  );

  const refundedGif = (
    <svg width='80' height='28' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='36' cy='16' rx='36' ry='8' fill='red' />

      <text
        x='48'
        y='16'
        dominant-baseline='middle'
        text-anchor='middle'
        font-family='Arial'
        font-size='10'
        fill='white'
      >
        <tspan x='36' dy='0'>
          Cancelled
        </tspan>
        <animate
          attributeName='opacity'
          values='0;1;0'
          dur='1.5s'
          repeatCount='indefinite'
        />{' '}
      </text>
    </svg>
  );

  return (
    <>
      <div className={`primary-scroll relative overflow-x-auto ${minHeight}`}>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500'>
          <thead className='bg-[#F1EEEC] h-[44px] text-xs text-gray-700 uppercase dark:text-gray-400'>
            <tr>
              {header?.map((column) => {
                return (
                  <th
                    scope='col'
                    className={`whitespace-nowrap overflow-hidden text-ellipsis max-w-[218.8px] text-xs not-italic font-bold leading-[normal] normal-case h-[44px] px-6 py-3 items-center p-6 text-foundation-brown font-inter text-small-para ${wideCols?.includes(column?.name) && 'min-w-[360px]'
                      }`}
                  >
                    {column.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <div
            className={`${table &&
              table?.includes('entities') &&
              (currentItems?.length < 5 || rows?.length < 5)
              ? 'mt-4'
              : 'mt-4'
              }`}
          ></div>
          <tbody>
            {(rows || currentItems) &&
              (table === '/billing' && noPaginate ? rows : currentItems)?.map(
                (row, index) => {
                  return (
                    <tr className='h-[72px]'>
                      {header?.map((column) => {
                        return (
                          <th
                            scope='row'
                            className={`${column.name === 'count'
                              ? `min-w-[159px]`
                              : 'max-w-[218.8px]'
                              } border-r-[#EAECF0] border-b-[#EAECF0] border-r border-solid border-b w-fit text-sm not-italic font-medium leading-[normal] text-[color:var(--Gray-600,#475467)] px-6 py-4 text-gray-900`}
                          >
                            {column.name == 'business' &&
                              row['isBlocked'] == true && (
                                // <div className='rotate-[-30deg] float-left'>
                                <span>{suspendedGif}</span>
                                // </div>
                              )}
                            {column.name == 'entityName' &&
                              row['isRefunded'] == true && (
                                // <div className='rotate-[-30deg] float-left'>
                                <span>{refundedGif}</span>
                                // </div>
                              )}{' '}
                            {column.name === 'status' ||
                              column.name === 'premium' ? (
                              <span
                                className={`truncate w-[100px]  whitespace-nowrap items-center ${row[column.name] === 'Delivered'
                                  ? 'bg-[#F1DDED]'
                                  : 'bg-[#F1EEEC]'
                                  } py-1 px-2 rounded-full ${table === '/billing' &&
                                    column.name === 'premium'
                                    ? 'text-[#77553D]'
                                    : ''
                                  }`}
                              >
                                {row[column.name] === 'UNPAID' ? (
                                  <Tooltip
                                    className='mt-[-10px] z-50'
                                    content='Premium amount will be available after 24 hours from shipment reporting time'
                                  >
                                    {row[column.name]}
                                  </Tooltip>
                                ) : (
                                  row[column.name]
                                )}
                              </span>
                            ) : column.name === 'trialed' ||
                              column.name === 'partial' ? (
                              <div className='w-full flex justify-center gap-2'>
                                {row[column.name] === true ? (
                                  <CustomIcon
                                    icon={tickIcon}
                                    className='h-[16px] w-[16px] flex justify-center items-center bg-[#77553d] rounded rounded-full p-1'
                                  />
                                ) : (
                                  <CustomIcon
                                    icon={cross}
                                    className='h-[16px] w-[16px] flex justify-center items-center p-[3px]'
                                  />
                                )}
                              </div>
                            ) : column.name === 'confirmation' ? (
                              <span
                                className={`truncate w-[100px] items-center ${row[column.name]
                                  ? 'bg-[#E6FFDE] text-[#228400]'
                                  : 'bg-[#FFF4E4] text-[#FF9900]'
                                  }  py-1 px-2 rounded-full`}
                              >
                                {row[column.name]
                                  ? row[column.name]
                                  : 'pending'}
                              </span>
                            ) : column.name === 'paid' ? (
                              <div>
                                {row[column.name] === 'Manually Paid' ? (
                                  'Manually Paid'
                                ) : row[column.name] ? (
                                  <CustomIcon
                                    className='h-[16px] w-[16px]'
                                    icon={approvedIcon}
                                  />
                                ) : (
                                  <CustomIcon
                                    className='h-[16px] w-[16px] '
                                    icon={redCross}
                                  />
                                )}
                              </div>
                            ) : column.name == 'from' ? (
                              <div className=''>
                                <span>{row[column.name][0]}</span>
                                <br />
                                <div className='text-xs font-normal'>
                                  {row[column.name][1]}
                                </div>
                              </div>
                            ) : (
                              <div className='overflow-hidden text-ellipsis whitespace-nowrap'>
                                {row[column.name]}
                              </div>
                            )}
                            {column.name === 'action' && (
                              <CustomText className='w-full flex justify-center relative'>
                                {menu && (
                                  <>
                                    <div
                                      ref={actionMenuRef}
                                      className='cursor-pointer  px-2 py-2'
                                      onClick={(e) => handleActionClick(e, row)}
                                    >
                                      <CustomIcon icon={actionButton} />
                                    </div>
                                    {row.id === toggleActionMenu
                                      ? React.cloneElement(menu, {
                                        upper:
                                          index === currentItems.length - 1 ||
                                          index === currentItems.length - 2,
                                      })
                                      : null}
                                  </>
                                )}

                                {Array.isArray(icons) &&
                                  icons?.map((icon) => {
                                    return (
                                      <div
                                        className='cursor-pointer'
                                        onClick={() => openViewClaimModal(row)}
                                      >
                                        <CustomIcon icon={icon} />
                                      </div>
                                    );
                                  })}
                              </CustomText>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      {!noPaginate && rows && rows.length ? (
        <div className='pt-[12px] pb-[16px] px-[10px] flex justify-end w-full'>
          <CustomPagination
            renderPaginationNumbers={renderPaginationNumbers}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            disableNext={disableNext}
            disablePrevious={disablePrevious}
          />
        </div>
      ) : null}
    </>
  );
}

export default CustomTable;
