import {
  FORMATE_AMOUNT,
  FORMATE_DATE,
  PARSIFY,
} from '../../../helpers/helpers';
import { useEffect, useState } from 'react';
import { filterBillingSchema } from '../schema/filterBillingSchema';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import moment from 'moment';
import { getAllShipments } from '../../../api';
import useApi from '../../../hook/useApi';
export const useAllBilling = () => {
  // const { billing } = useSelector((state) => state);
  const user = JSON.parse(localStorage.getItem('login-user'));
  // const { shipments } = useSelector((state) => state);
  const [shipments, setShipments] = useState([]);
  const { handleSubmit, register, reset, errors, setValue } =
    useHookFormSubmission(filterBillingSchema);

  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isFilterCleared, setIsFilterCleared] = useState(true);

  const [filteredTotalValue, setFilteredTotalValue] = useState(0);
  const [filteredTotalPremium, setFilteredTotalPremium] = useState(0);
  const [filteredTotalRebate, setFilteredTotalRebate] = useState(0);
  const [loading, setLoading] = useState(false);

  const [getAllShipmentsCall] = useApi(getAllShipments);

  useEffect(() => {
    user && getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const response = await getAllShipmentsCall();
    setShipments(response?.data?.shipments);
    // console.log('res:', response);
    setLoading(false);
  };

  const entityOptions = [
    ...new Set(shipments?.map((shipment) => shipment.location?.entity?.name)),
  ].map((name) => ({
    id: name,
    state: name,
  }));

  useEffect(() => {
    if (isFilterCleared === true) {
      const modifyBilling = shipments?.map((bill) => {
        const to = PARSIFY(bill.to);
        const from = PARSIFY(bill.from.address);
        const rebate = (bill?.premium / 1.03) * 0.2;

        return {
          ...bill,
          entity: bill.location.entity?.name,
          from: bill.from.nickName,
          to: to ? to.name : '---',
          confirmation: bill.confirmationId,
          reported: FORMATE_DATE(bill.createdAt),
          pickupDate: FORMATE_DATE(bill.pickUpDate),
          deliveryDate: FORMATE_DATE(bill.deliveryDate),
          status: bill.isDelivered ? 'Delivered' : 'InTransit',
          value: FORMATE_AMOUNT(bill.totalValue),
          premium: FORMATE_AMOUNT(bill.premium),
          rebate: FORMATE_AMOUNT(rebate),
          paid: bill.paid,
          Insured: bill.location.entity?.name,
          'Confirmation #': bill.confirmationId,
          'Merchandise Type': bill.shipmentType.name,
          'Customer Ref #':
            (PARSIFY(bill.to) && PARSIFY(bill.to).reference) || '---',
          'From Address':
            (from?.street ? from?.street + ', ' : '') +
            (from?.city ? from?.city + ', ' : '') +
            (from?.state ? from?.state + ', ' : '') +
            (from?.country ? from?.country : '') +
            ' ' +
            (!from?.zipCode || from?.street?.includes(from?.zipCode)
              ? ''
              : from?.zipCode),
          'To Address': `${to?.street ? to?.street : ''}, ${to?.city ? to?.city : ''
            } ${to?.state ? to?.state : ''} ${!from?.zipCode || from?.street?.includes(from?.zipCode)
              ? ''
              : from?.zipCode
            }
          `,
          'Means Transport': bill?.carrier?.name,
          'Pick Up Date': FORMATE_DATE(bill.pickUpDate),
          'Delivery Date': FORMATE_DATE(bill.deliveryDate),
          'Insured Value': FORMATE_AMOUNT(bill.totalValue),
          Rebate: FORMATE_AMOUNT(rebate),
        };
      });
      setFilteredData(modifyBilling);
    }
  }, [isFilterCleared, shipments]);

  const handleOpenFilterModal = () => setShowFilterModal(true);
  const handleCloseFilterModal = () => setShowFilterModal(false);

  const handleFilterSubmit = (filters) => {
    setFilteredData([]);

    const { entity, status, fromDate, toDate } = filters;

    // const formatFrom = fromDate ? FORMATE_DATE(fromDate, 'mm/dd/yy') : null;
    // const formatTo = toDate ? FORMATE_DATE(toDate, 'mm/dd/yy') : null;

    const formatFrom = fromDate ? moment.utc(fromDate).startOf('day') : null;
    const formatTo = toDate ? moment.utc(toDate).endOf('day') : null;

    // console.log('formatFrom:', formatFrom);
    // console.log('formatTo:', formatTo);

    let _filteredData = shipments?.filter((bill) => {
      const isEntityMatch = entity
        ? bill?.location?.entity?.name?.toLowerCase() === entity?.toLowerCase()
        : bill;
      const isStatusMatch =
        status && status?.toLowerCase() !== 'both'
          ? status?.toLowerCase() === 'delivered'
            ? bill.isDelivered
            : !bill.isDelivered
          : bill;
      // const isStartDateMatch = formatFrom
      //   ? FORMATE_DATE(bill.createdAt, 'mm/dd/yy') >= formatFrom
      //   : bill;
      // const isEndDateMatch = formatTo
      //   ? FORMATE_DATE(bill.createdAt, 'mm/dd/yy') <= formatTo
      //   : bill;

      const billCreatedAt = moment.utc(bill.createdAt);

      const isStartDateMatch = formatFrom
        ? billCreatedAt.isSameOrAfter(formatFrom)
        : true;
      const isEndDateMatch = formatTo
        ? billCreatedAt.isSameOrBefore(formatTo)
        : true;

      return (
        isEntityMatch && isStatusMatch && isStartDateMatch && isEndDateMatch
      );
    });

    let _totalValue = 0;
    let _totalPremium = 0;
    let _totalRebate = 0;

    const filteredBilling = _filteredData.map((bill) => {
      _totalValue += bill?.totalValue || 0;
      _totalPremium += bill?.premium;

      const rebate = (bill?.premium / 1.03) * 0.2;

      _totalRebate += rebate;

      return {
        ...bill,
        entity: bill.location.entity?.name,
        from: bill.from.nickName,
        to: (PARSIFY(bill.to) && PARSIFY(bill.to).name) || '---',
        confirmation: bill.confirmationId,
        reported: FORMATE_DATE(bill.createdAt),
        pickupDate: FORMATE_DATE(bill.pickUpDate),
        deliveryDate: FORMATE_DATE(bill.deliveryDate),
        status: bill.isDelivered ? 'Delivered' : 'InTransit',
        value: FORMATE_AMOUNT(bill.totalValue),
        premium: FORMATE_AMOUNT(bill.premium),
        rebate: FORMATE_AMOUNT(rebate),
        paid: bill.paid,
        Insured: bill.location.entity?.name,
        'Confirmation #': bill.confirmationId,
        'Merchandise Type': bill.shipmentType.name,
        'Customer Ref #':
          (PARSIFY(bill.to) && PARSIFY(bill.to).reference) || '---',
        'From Address':
          PARSIFY(bill.from.address)?.street +
          ', ' +
          PARSIFY(bill.from.address)?.city +
          ', ' +
          PARSIFY(bill.from.address)?.state +
          ', ' +
          PARSIFY(bill.from.address)?.country +
          PARSIFY(bill.from.address)?.zipCode,
        'To Address':
          PARSIFY(bill.to)?.street +
          ', ' +
          PARSIFY(bill.to)?.city +
          ',' +
          PARSIFY(bill.to)?.state +
          ', ' +
          PARSIFY(bill.to)?.country +
          PARSIFY(bill.to)?.zipCode,
        'Means Transport': bill?.carrier?.name,
        'Pick Up Date': FORMATE_DATE(bill.pickUpDate),
        'Delivery Date': FORMATE_DATE(bill.deliveryDate),
        'Insured Value': FORMATE_AMOUNT(bill.totalValue),
        Rebate: FORMATE_AMOUNT(rebate),
      };
    });

    setFilteredTotalValue(FORMATE_AMOUNT(_totalValue));
    setFilteredTotalPremium(FORMATE_AMOUNT(_totalPremium));
    setFilteredTotalRebate(FORMATE_AMOUNT(_totalRebate));

    setFilteredData(filteredBilling);
    setIsFilterCleared(false);
    setShowFilterModal(false);
  };

  const handleFilterReset = () => {
    reset();
    setFilteredData([]);
    handleCloseFilterModal();
    setIsFilterCleared(true);
  };

  // return { data: modifyBilling };
  return {
    data: filteredData,
    showFilterModal,
    handleOpenFilterModal,
    handleCloseFilterModal,
    handleFilterSubmit,
    handleFilterReset,
    handleSubmit,
    register,
    errors,
    setValue,
    entityOptions,
    isFilterCleared,
    setIsFilterCleared,
    filteredTotalValue,
    filteredTotalPremium,
    filteredTotalRebate,
    loading,
  };
};
