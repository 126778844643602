import React, { useEffect, useState } from 'react';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomDropdown,
  CustomInput,
} from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomDatePicker from '../../../common/custom/CustomDatePicker';
import { PATHS } from '../../../constants/paths';
import { useEditShipment } from './useEditShipment';
import { useCustomerDetails } from '../../modals/customer-details-modal/useCustomerDetails';
import AutoCompleteComponent from '../../components/auto-complete';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
} from '../../../api/services/address.service';
import CustomDropdown2 from '../../../common/custom/custom-drop-down/index2';
import {
  BEAUTIFY_ADDRESS,
  FILE_TYPE,
  FORMATE_AMOUNT,
  PREMIUM,
  REBATE,
} from '../../../helpers/helpers';
import { uploadFileIcon } from '../../../assets';
import FileUpload from '../../../common/custom/file-upload/file-upload.component';
import FilePreview from './editAttachmentsPreview';

const EditShipment = () => {
  const {
    rate,
    data,
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    closeCustomerDetailModal,
    openCustomerDetailModal,
    getUpdateCustomerDetailData,
    streetPlaceholder,
    customerDetailModalOpen,
    declaredValue,
    handleDeclaredValue,
    navigate,
    updateLoading,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    isLoading,
    handleUploadAttachments,
    documents,
    handleUploadAttachmentsCancel,
    user,
    addresses,
    fieldsFromGoogle,
    setFieldsFromGoogle,
    zipCode, setZipCode
  } = useEditShipment();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [resetState, setResetState] = useState(null);


  useEffect(() => {
    let addressTo = data ? JSON.parse(data.to) : '';

    setValue(
      'referenceNumber',
      addressTo?.reference ? addressTo.reference : ''
    );

    let name = addressTo?.name + ' ';
    let street = addressTo?.street?.length > 1 ? addressTo.street : '';
    let zipCode =
      addressTo?.zipCode?.length > 1 ? addressTo.zipCode + ', ' : '';
    let city = addressTo?.city + ' ' || '';
    let state = addressTo?.state + ' ' || '';
    let country = addressTo?.country || '';
    setValue(
      'destinationName',
      name + street
      // + zipCode + city + state + country
    );
    setFieldsFromGoogle(name + street)
    setValue('unitNumber', addressTo?.unitNo ? addressTo.unitNo : '');
    setValue('zipCode', addressTo?.zipCode ? addressTo.zipCode : '');
    setValue('city', addressTo?.city ? addressTo.city : '');
    setValue('state', addressTo?.state ? addressTo.state : '');
    setValue(
      'additionalNote',
      data?.additionalNote ? data?.additionalNote : ''
    );
    setValue('documents', JSON.stringify(documents));
    setZipCode(addressTo?.zipCode)
    setSelectedCountry(addressTo?.country);
    setSelectedState(addressTo?.state);
    setSelectedCity(addressTo?.city);

    const _fetchCountries = async () => {
      try {
        let _countries = await fetchCountries();

        _countries = _countries.map((item) => ({
          label: item.country,
          value: item.country,
        }));
        const existingCountry = JSON.parse(data.to)?.country;
        _countries.push({ label: existingCountry, value: existingCountry });
        setCountries(_countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    _fetchCountries();
  }, [data, documents]);

  const handleGoogleAutoCompleteChange = (newAddress, address) => {
    console.log(address, 'address')
    setFieldsFromGoogle(newAddress);
    setSelectedCountry(address?.country)
    setSelectedState(address?.state)
    setSelectedCity(address?.city)
    setZipCode(address?.zipCode)
  };

  console.log(fieldsFromGoogle, 'fieldsFromGoogle')
  console.log(selectedCountry, 'selectedCountry')
  console.log(selectedState, 'selectedState')
  console.log(selectedCity, 'selectedCity')
  console.log(zipCode, 'zipCode')

  useEffect(() => {
    const _fetchStates = async () => {
      try {
        let _states = await fetchStates(selectedCountry);
        _states = _states.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        const existingState = JSON.parse(data.to)?.state;
        _states.push({ label: existingState, value: existingState });
        setStates(_states);
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    setResetState(null);
    _fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    setResetState(selectedState);
  }, [selectedState]);

  useEffect(() => {
    const _fetchCities = async () => {
      try {
        if (resetState != null) {
          let _cities = await fetchCities(selectedCountry, selectedState);
          _cities = _cities.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          const existingCity = JSON.parse(data.to)?.city;
          _cities.push({ label: existingCity, value: existingCity });
          setCities(_cities);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };
    _fetchCities();
  }, [resetState, selectedCountry, selectedState]);

  const parsedToAddress = JSON.parse(data?.to);

  // const { fieldsFromGoogle, handleGoogleAutoComplete } = useCustomerDetails(
  //   data,
  //   getUpdateCustomerDetailData
  // );

  const customHtml = `
  <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
    <div class="flex flex-col items-center">
    <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]"/>
    <div class="flex flex-col gap-1 mt-3 items-center">
     <div class="flex gap-1">
     <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
     <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
     </div>
      <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG, DOC or PDF</div>
    </div>
    </div>
  </div> 
`;
  return (
    <>
      <CustomContainer>
        <ClientsPanelHeader />
        <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
          <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
            Edit Shipment
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
              <div className='mb-4'>
                {/* <CustomInput
                  placeholder={(
                    data?.from?.nickName +
                    ' (' +
                    (JSON.parse(data?.from?.address)?.street?.length > 1
                      ? JSON.parse(data?.from?.address)?.street + ', '
                      : 'Str') +
                    (JSON.parse(data?.from?.address)?.unitNo?.length > 1
                      ? JSON.parse(data?.from?.address)?.unitNo + ', '
                      : '') +
                    JSON.parse(data?.from?.address)?.country +
                    ')'
                  ).replace(/\s+/g, ' ')}
                  label='From Location'
                  name='fromLocation'
                  register={register}
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                /> */}
                <CustomDropdown
                  label='From Location'
                  placeholder={(
                    data?.from?.nickName +
                    ' (' +
                    (JSON.parse(data?.from?.address)?.street?.length > 1
                      ? JSON.parse(data?.from?.address)?.street + ', '
                      : '') +
                    (JSON.parse(data?.from?.address)?.unitNo?.length > 1
                      ? JSON.parse(data?.from?.address)?.unitNo + ', '
                      : '') +
                    JSON.parse(data?.from?.address)?.country +
                    ')'
                  ).replace(/\s+/g, ' ')}
                  options={addresses}
                  setValue={setValue}
                  name='fromLocation'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
              <div className='mb-4'>
                {/* <CustomInput
                  // placeholder={
                  //   parsedToAddress.name +
                  //   ' ' +
                  //   parsedToAddress.street +
                  //   ' ' +
                  //   parsedToAddress.zipCode +
                  //   ' ' +
                  //   parsedToAddress.city +
                  //   ' ' +
                  //   parsedToAddress.state +
                  //   ' ' +
                  //   parsedToAddress.country
                  // }
                  label='To / Destination Address'
                  name='destinationName'
                  register={register}
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                /> */}
                {/* <AutoCompleteComponent
                  setValue={setValue}
                  trigger={trigger}
                  isFormatted={true}
                  errors={errors}
                  register={register}
                  label='To / Destination Address'
                  name='destinationName'
                  address={fieldsFromGoogle}
                  onChange={handleGoogleAutoCompleteChange}
                /> */}
                <AutoCompleteComponent
                  setValue={setValue}
                  trigger={trigger}
                  isFormatted={true}
                  register={register}
                  errors={errors}
                  label='To / Destination Address'
                  name='destinationName'
                  address={fieldsFromGoogle}
                  onChange={handleGoogleAutoCompleteChange}
                  required
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
              <div>
                <CustomDropdown2
                  label='Country*'
                  placeholder={parsedToAddress?.country}
                  options={countries}
                  name='country'
                  errors={errors}
                  setValue={setSelectedCountry}
                  value={selectedCountry}
                />
              </div>
              <div>
                <CustomDropdown2
                  label='State*'
                  placeholder={parsedToAddress?.state}
                  options={states}
                  name='state'
                  errors={errors}
                  setValue={setSelectedState}
                  value={selectedState}
                />
              </div>
              <div>
                <CustomDropdown2
                  label='City*'
                  placeholder={parsedToAddress?.state}
                  options={cities}
                  name='city'
                  errors={errors}
                  setValue={setSelectedCity}
                  value={selectedCity}
                />
              </div>
              <div>
                <CustomInput
                  name='zipCode'
                  placeholder={parsedToAddress.zipCode}
                  label='Zip Code'
                  register={register}
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
              <div>
                <CustomInput
                  register={register}
                  name='unitNumber'
                  placeholder={parsedToAddress.unitNo}
                  label='Unit Number'
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                />
              </div>
              <div>
                <div>
                  <CustomInput
                    placeholder={parsedToAddress.reference}
                    label='Reference'
                    register={register}
                    name='referenceNumber'
                    errors={errors}
                    trigger={trigger}
                    setValue={setValue}
                  />
                </div>
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className='mt-[5px]'>
                <CustomDropdown
                  label='Merch Type'
                  placeholder={data?.shipmentType.name}
                  options={[
                    { id: '1', name: 'New Furniture' },
                    { id: '2', name: 'Furniture and Accessories' },
                    { id: '3', name: 'Light Fixtures' },
                    { id: '4', name: 'Framed Artwork' },
                    { id: '5', name: 'Sculptures' },
                    { id: '6', name: 'Stretched Canvases' },
                  ]}
                  setValue={setValue}
                  name='merchandiseType'
                />
              </div>
              <div className='md:mt-[5px]'>
                <div>
                  <CustomDropdown
                    label='Select Mover'
                    placeholder={data?.carrier?.name}
                    options={[
                      { id: 18, name: 'AN Trucking' },
                      { id: 16, name: 'Bluegrace' },
                      { id: 17, name: 'CMV Super Trucking' },
                      { id: 13, name: 'CR Creative' },
                      { id: 19, name: 'Daylight' },
                      { id: 7, name: 'Elite Trucking Services' },
                      { id: 8, name: 'H20 Logistics ' },
                      { id: 2, name: 'Julio Express' },
                      { id: 1, name: 'Netbonds' },
                      { id: 14, name: 'New Penn Mortor Express' },
                      { id: 5, name: 'Nippon Express' },
                      { id: 9, name: 'Premier Installations At Linea' },
                      { id: 3, name: 'Rigos Delivery' },
                      { id: 21, name: 'ScanGlobal' },
                      {
                        id: 12,
                        name: 'Total Transportation & Distribution, Inc',
                      },
                      { id: 10, name: 'Tramo ' },
                      { id: 20, name: 'West Direct' },
                      { id: 11, name: 'Wetzel & Son Moving & Storage ' },
                      { id: 6, name: 'White Glove' },
                      { id: 15, name: 'YRC Freight' },
                      { id: 4, name: 'Other' },
                    ]}
                    name='carrier'
                    setValue={setValue}
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className=''>
                <div className='flex rounded-md relative'>
                  <CustomDatePicker
                    label='Pickup Date'
                    name='pickupDate'
                    errors={errors}
                    register={register}
                  />
                </div>
              </div>
              <div>
                <div className='flex rounded-md relative'>
                  <CustomDatePicker
                    label='Delivery Date'
                    name='deliveryDate'
                    errors={errors}
                    register={register}
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-[15px]'>
              <div className='mb-4'>
                <CustomInput
                  name='declaredValue'
                  readOnly={user?.type === 'ADMIN' ? false : true}
                  register={register}
                  placeholder='Declared value (IN USD)'
                  type='number'
                  label='Declared Value'
                  value={declaredValue}
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                  onChange={(e) => handleDeclaredValue(e.target.value)}
                />

                <p
                  className={`text-xs sm:text-sm text-gray-600 ${errors.declaredValue ? 'mt-6' : 'mt-2'
                    } `}
                >
                  <span className='text-[#2F950B] font-inter text-[14px] font-[500] items-center'>
                    Premium:{' '}
                    <span className='text-[#2F950B] text-sm italic font-semibold leading-5'>
                      {FORMATE_AMOUNT(data?.premium)}
                    </span>
                  </span>
                  <span className='px-1 text-[#E59111] text-sm italic font-medium leading-5'>
                    -
                  </span>
                  <span className='text-[#E59111] font-inter text-[14px] font-[500]'>
                    Rebate:{' '}
                    <span className='text-[#E59111] text-sm italic font-semibold leading-5'>
                      {REBATE(declaredValue, rate)}
                    </span>
                  </span>
                </p>
              </div>

              <div className='mt-[5px]'>
                <CustomInput
                  label='Confirmation Id'
                  readOnly={true}
                  value={data?.confirmationId || '---'}
                  // setValue={setValue}
                  name='confirmationId'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  name='paymentStatus'
                  readOnly={true}
                  // placeholder="Payment Status"
                  // type="number"
                  label='Payment Status'
                  value={data?.paid === true ? 'Paid' : 'Pending'}
                />
              </div>

              <div className='mt-[]'>
                <CustomInput
                  label='Shipment Status'
                  readOnly={true}
                  value={data?.approved === true ? 'Approved' : 'Pending'}
                  // setValue={setValue}
                  name='shipmentStatus'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  name='additionalNote'
                  register={register}
                  placeholder={data?.additionalNote || '---'}
                  type='text'
                  label='Additional Note'
                  errors={errors}
                  trigger={trigger}
                  setValue={setValue}
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
              {documents && documents.length > 0 ? (
                <FilePreview
                  files={documents}
                  isLoading={isLoading}
                  handleCancel={handleUploadAttachmentsCancel}
                />
              ) : null}

              <FileUpload
                handleChange={handleUploadAttachments}
                fileTypes={FILE_TYPE}
                customHtml={customHtml}
                multiple={true}
              />
            </div>
            <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
              <CustomButton
                type='button'
                onClick={() => {
                  navigate(PATHS.SHIPMENTS);
                }}
                className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
                variant='outline'
                text='Cancel'
              />

              <CustomButton
                className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
                text='Update'
                disabled={updateLoading ? true : false}
              />
            </div>
          </form>
        </div>
      </CustomContainer>
    </>
  );
};

export default EditShipment;
