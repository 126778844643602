export const SHIPMENTS = [
  { id: 1, title: 'Entity', name: 'entityName' },
  { id: 1, title: 'From', name: 'from' },
  { id: 11, title: 'Reference', name: 'reference' },
  { id: 2, title: 'Recipient', name: 'recipient' },
  { id: 3, title: 'Carrier', name: 'carrier' },
  { id: 4, title: 'Pickup', name: 'pickup' },
  { id: 5, title: 'Delivery', name: 'delivery' },
  { id: 6, title: 'Value', name: 'value' },
  { id: 7, title: 'Premium', name: 'premium' },
  { id: 8, title: 'Confirmation', name: 'confirmation' },
  { id: 9, title: 'Paid', name: 'paid' },
  { id: 10, title: 'Status', name: 'status' },
  { id: 11, title: 'Trial', name: 'trialed' },

  { id: 12, title: 'Action', name: 'action' },
];

export const PENDING_SHIPMENTS = [
  { id: 11, title: 'Entity', name: 'entity' },
  { id: 1, title: 'From', name: 'from' },
  { id: 2, title: 'Recipient', name: 'recipient' },
  { id: 9, title: 'Reference', name: 'reference' },
  { id: 3, title: 'Carrier', name: 'carrier' },
  { id: 4, title: 'Pickup', name: 'pickup' },
  { id: 5, title: 'Delivery', name: 'delivery' },
  { id: 6, title: 'Value', name: 'value' },
  { id: 7, title: 'Premium', name: 'premium' },

  { id: 8, title: 'Confirmation', name: 'confirmation' },

  { id: 10, title: 'Action', name: 'action' },
];

export const AllEntities = [
  { id: 1, title: 'Business', name: 'business' },
  { id: 2, title: 'TIN', name: 'salesperson' },
  { id: 3, title: 'Email', name: 'email' },
  { id: 4, title: 'Phone#', name: 'phone' },
  { id: 5, title: 'Industry', name: 'industry' },
  { id: 6, title: 'Joined', name: 'joined' },
  { id: 7, title: 'Count', name: 'count' },
  { id: 8, title: 'Action', name: 'action' },
];

export const NewEntities = [
  { id: 1, title: 'Business', name: 'business' },
  { id: 2, title: 'TIN', name: 'salesperson' },
  { id: 3, title: 'Email', name: 'email' },
  { id: 4, title: 'Phone#', name: 'phone' },
  { id: 5, title: 'Industry', name: 'industry' },
  { id: 6, title: 'Since', name: 'since' },
  { id: 7, title: 'Action', name: 'action' },
];

export const MememberHeader = [
  { id: 1, title: 'First Name', name: 'fname' },
  { id: 2, title: 'Last Name', name: 'lname' },
  { id: 3, title: 'Email', name: 'email' },
  { id: 4, title: 'Phone#', name: 'phone' },
  { id: 5, title: 'Role', name: 'role' },
  { id: 6, title: 'Date Created', name: 'dateCreated' },
];

export const ShipmentInformation = [
  { id: 1, title: 'From', name: 'from' },
  { id: 2, title: 'To', name: 'to' },
  { id: 3, title: 'Mover', name: 'mover' },
  { id: 4, title: 'Pick up', name: 'pickup' },
  { id: 5, title: 'Delivery', name: 'delivery' },
  { id: 6, title: 'Value', name: 'value' },
  { id: 7, title: 'Condirmation', name: 'condirmation' },
  { id: 8, title: 'Status', name: 'status' },
];

export const AddressInformation = [
  { id: 1, title: 'Location Nick Name', name: 'nickName' },
  { id: 2, title: 'Address', name: 'formattedAddress' },
  { id: 3, title: 'Action', name: 'action' },
];

// export const PaymentLocationHeader = [
//   { id: 1, title: 'Location Name', name: 'locationName' },
//   { id: 2, title: 'Address', name: 'address' },
//   { id: 3, title: 'Action', name: 'action' },
// ];

export const PAYMENT_METHODS = [
  { id: 1, title: 'Payment Method', name: 'paymentMethod' },
  { id: 2, title: 'Type', name: 'type' },
  { id: 3, title: 'Provider', name: 'provider' },
  { id: 4, title: 'Expiration', name: 'expiration' },
  // { id: 5, title: 'Default', name: 'default' },
  { id: 6, title: 'Action', name: 'action' },
];

export const CardInformationHeader = [
  { id: 1, title: 'Card Company', name: 'card_company' },
  { id: 2, title: 'Card Number', name: 'card_number' },
  { id: 3, title: 'Expiry Year', name: 'e_year' },
  { id: 4, title: 'Expiry Month', name: 'e_month' },
  { id: 5, title: 'Customer Name', name: 'c_name' },
  { id: 6, title: 'Email', name: 'c_email' },
  { id: 7, title: 'Action', name: 'action' },
];

export const CLAIMS_HISTORY = [
  { id: 1, title: 'Stage', name: 'subject' },
  { id: 2, title: 'Date', name: 'date' },
  { id: 3, title: 'Status', name: 'status' },
];
export const CLAIMS = [
  { id: 1, title: 'Entity', name: 'entity' },
  { id: 2, title: 'Filed By', name: 'filedBy' },
  { id: 3, title: 'Claim ID', name: 'claimId' },
  { id: 11, title: 'Shipment Value', name: 'shipValue' },
  { id: 4, title: 'Claim Value', name: 'value' },
  { id: 5, title: 'Adjusted Amount', name: 'adjusted' },
  { id: 6, title: 'Partial', name: 'partial' },
  { id: 7, title: 'Filed On', name: 'filledOn' },
  { id: 8, title: 'Claim Status', name: 'status' },
  { id: 9, title: 'Action', name: 'action' },
];

export const BILLING = [
  { id: 1, title: 'Company Name', name: 'entity' },
  // { id: 2, title: 'From', name: 'from' },
  // { id: 3, title: 'To', name: 'to' },
  { id: 4, title: 'Confirmation', name: 'confirmation' },
  { id: 5, title: 'Reported', name: 'reported' },
  // { id: 6, title: 'Pickup', name: 'pickupDate' },
  // { id: 7, title: 'Delivery', name: 'deliveryDate' },
  { id: 8, title: 'Status', name: 'status' },
  { id: 9, title: 'Value', name: 'value' },
  { id: 10, title: 'Premium', name: 'premium' },
  { id: 11, title: 'Rebate', name: 'rebate' },
  { id: 12, title: 'Paid', name: 'paid' },
  // { id: 13, title: 'Reward', name: 'reward' },
];

export const CARRIERS = [
  { id: 1, title: 'ID', name: 'id' },
  { id: 2, title: 'Name', name: 'name' },
  { id: 3, title: 'Address', name: 'address' },
  { id: 4, title: 'Created At', name: 'createdAt' },
  { id: 5, title: 'Action', name: 'action' },
];

export const INDUSTRIES = [
  { id: 1, title: 'ID', name: 'id' },
  { id: 2, title: 'Name', name: 'name' },
  { id: 3, title: 'Action', name: 'action' },
];

export const SHIPMENT_TYPES = [
  { id: 1, title: 'ID', name: 'id' },
  { id: 2, title: 'Name', name: 'name' },
  { id: 3, title: 'Action', name: 'action' },
];

export const SALES_MEMBERS = [
  { id: 1, title: 'ID', name: 'id' },
  { id: 2, title: 'First Name', name: 'firstName' },
  { id: 3, title: 'Last Name', name: 'lastName' },
  { id: 4, title: 'Email Address', name: 'emailAddress' },
  { id: 6, title: 'Action', name: 'action' },
];

export const SALES_MEMBERS_COMMISSION = [
  { id: 1, title: 'ID', name: 'id' },
  { id: 2, title: 'Premium', name: 'premium' },
  { id: 3, title: 'Commission', name: 'commission' },
  { id: 4, title: 'Date', name: 'date' },
];
