import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import Attachments from './components/attachments.component';
import Checklist from './components/checklist.component';
import Details from './components/details.component';

import ShipmentDetail from './components/shipment-detail.component';
import UpdateClaim from './components/update-claim.component';
import UseClaimDetails from './use-claim-details.hook';
import { extractArrayData } from '../../../helpers/helpers';
import SignedRelease from './components/signed-release.component';
import { Grid } from '@mui/material';

function ClaimDetails() {
  const {
    claim,
    claimType,
    options,
    setClaimType,
    handleSubmit,
    register,
    errors,
    handleUpdateClaim,
    claimData,
    onCancel,
    claimClosefile,
    setClaimCloseFile,
    fileError,
    setFileError,
    adjustmentAmount,
    setAdjustmentAmount,
  } = UseClaimDetails();

  return (
    <div className='sm:px-[120px]'>
      <ClientsPanelHeader />
      <Grid container spacing="24px">
        <Grid item xs={12} md={4}>
          <UpdateClaim
            claim={claimData}
            claimType={claimType}
            register={register}
            errors={errors}
            options={options}
            setClaimType={setClaimType}
            handleSubmit={handleSubmit}
            handleUpdateClaim={handleUpdateClaim}
            onCancel={onCancel}
            claimClosefile={claimClosefile}
            setClaimCloseFile={setClaimCloseFile}
            fileError={fileError}
            setFileError={setFileError}
            adjustmentAmount={adjustmentAmount}
            setAdjustmentAmount={setAdjustmentAmount}
          />
          {/* <Checklist
            invoice={extractArrayData(claimData?.invoice)}
            receipt={extractArrayData(claimData?.receipts)}
            attachment={extractArrayData(claimData?.attachments)}
          /> */}
        </Grid>
        <Grid item xs={12} md={8}>
          <ShipmentDetail claim={claimData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Attachments
            invoice={extractArrayData(claimData?.invoice)}
            receipt={extractArrayData(claimData?.receipts)}
            attachment={extractArrayData(claimData?.attachments)}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Details claim={claimData} />
          {claimData?.signedReleaseClaim && (
            <SignedRelease files={JSON.parse(claimData?.signedReleaseClaim)} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ClaimDetails;
