import { useEffect, useRef, useState } from 'react';
import {
  approveShipmentMutation,
  cancelShipmentMutation,
  changeConfirmStatusMutation,
  changePaidStatusMutation,
  getAllShipments,
} from '../../../api';
import useApi from '../../../hook/useApi';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import {
  FORMATE_AMOUNT,
  FORMATE_DATE,
  PARSIFY,
  PREMIUM,
  isDatePassed,
} from '../../../helpers/helpers';
import useOutsideClick from '../../../hook/useOutsideClick';
import { storeAllShipmentsAction } from '../../../store';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { filterShipmentSchema } from '../schema/filterShipmentSchema';
import { refundShipmentMutation } from '../../../api/services/shipments.service';
import { resetReportShipmentFormAction } from '../../../store/slices/formsSlice';
import { PATHS } from '../../../constants/paths';

export const useShipments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actionMenuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState([]);

  const [attachments, setAttachments] = useState([]);
  const [shipmentActionClickedId, setShipmentActionClickedId] = useState(null);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShipmentStatusModal, setShowShipmentStatusModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [shipmentActionClickedData, setShipmentActionClickedData] =
    useState(null);

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  const user = JSON.parse(localStorage.getItem('login-user'));
  const filterFormData = JSON.parse(localStorage.getItem('formFilter'));

  const [getAllShipmentsCall] = useApi(
    getAllShipments,
    storeAllShipmentsAction
  );

  const [refundShipmentCall] = useApi(refundShipmentMutation);
  const [cancelShipmentCall] = useApi(cancelShipmentMutation);
  const [changeShipmentStatus] = useApi(approveShipmentMutation);
  const [changeConfirmStatusCall] = useApi(changeConfirmStatusMutation);
  const [confirmPaymentStatusCall] = useApi(changePaidStatusMutation);

  const { handleSubmit, register, reset, errors, setValue } =
    useHookFormSubmission(filterShipmentSchema);

  // useEffect(() => {
  //   user && getAllShipmentsCall();
  // }, []);

  useEffect(() => {
    user && getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const response = await getAllShipmentsCall();
    setShipments(response?.data?.shipments);
    setLoading(false);
  };

  const handleViewAttachments = (data) => {
    setOpen(true);
    setAttachments(
      [
        // ...attachments,
        data?.documents ? JSON.parse(data.documents) : [],
        data?.deliveryDocuments ? JSON.parse(data.deliveryDocuments) : [],
      ].flat()
    );
  };

  const handleClose = () => {
    setOpen(!open);
  };

  function cleanAddressString(addressString) {
    if (!addressString) return '';

    // Split by commas and clean each part
    const parts = addressString
      .split(',')
      .map(part => part.trim())
      .filter(Boolean);

    // Helper function to check if strings are similar
    const isSimilar = (str1, str2) => {
      if (!str1 || !str2) return false;
      str1 = str1.toLowerCase().trim();
      str2 = str2.toLowerCase().trim();

      // Check if one is contained within the other
      if (str1.includes(str2) || str2.includes(str1)) {
        return true;
      }

      // Check if they're the same
      return str1 === str2;
    };

    // Keep first occurrence of each unique part
    const seen = new Set();
    const cleanedParts = parts.filter(part => {
      // Check if we've seen a similar part before
      const isDuplicate = Array.from(seen).some(seenPart =>
        isSimilar(seenPart, part)
      );

      if (!isDuplicate) {
        seen.add(part);
        return true;
      }
      return false;
    });

    return cleanedParts.join(', ');
  }

  const modifiedShipmentsData = shipments
    ?.filter((shipment) => shipment.approved === true)
    .map((shipment) => {
      const parsedFromAddress = PARSIFY(shipment.from.address);
      // console.log(shipment, 'shipment.from.address')
      // console.log(shipment?.from?.nickName, 'shipment?.from?.nickName')
      // Prepare the address string
      // Assuming the address is already created from parsedFromAddress
      let addressString = parsedFromAddress?.street +
        ' ' +
        parsedFromAddress?.city +
        ' ' +
        parsedFromAddress?.state +
        ' ' +
        parsedFromAddress?.country +
        ', ' +
        parsedFromAddress?.zipCode;

      // console.log(addressString, 'addressString')
      const cleanAddress = cleanAddressString(addressString);
      // Create the fromAddress array with the unique address
      const fromAddress = [
        shipment?.from?.nickName,
        cleanAddress
      ];

      // console.log(fromAddress, 'fromAddress');
      // console.log(fromAddress, 'fromAddress')
      return {
        entityName: shipment.location.name || '---',
        deliveredStatus: shipment.isDelivered,
        confirmationId: shipment.confirmationId,
        paid: shipment?.isManuallyPaid ? 'Manually Paid' : shipment.paid,
        approvedStatus: shipment.approved,
        isManuallyPaid: shipment.isManuallyPaid,
        id: shipment.id,
        from: fromAddress,
        documents: shipment.documents,
        recipient: (PARSIFY(shipment.to) && PARSIFY(shipment.to).name) || '---',
        carrier: shipment.carrier && shipment.carrier.name,
        pickup: FORMATE_DATE(shipment?.pickUpDate),
        // pickup: moment.utc(shipment?.pickUpDate).format('MM/DD/YYYY'),
        delivery: FORMATE_DATE(shipment?.deliveryDate),
        // delivery: moment.utc(shipment?.deliveryDate).format('MM/DD/YYYY'),
        value: FORMATE_AMOUNT(shipment.totalValue),
        premium: shipment?.premium
          ? FORMATE_AMOUNT(shipment.premium)
          : 'UNPAID',
        confirmation: shipment.confirmationId ? shipment.confirmationId : 'N/A',
        // isDatePassed(shipment.pickUpDate)
        status: shipment.isDelivered ? 'Delivered' : 'In-Transit',
        // : '---',
        isRefunded: shipment?.status === 1,
        trialed: shipment.subscribers == 'true' ? true : false,
        reference:
          (PARSIFY(shipment.to) && PARSIFY(shipment.to).reference) || '---',
      };
    });

  const entityOptionData = [
    ...new Set(shipments?.map((shipment) => shipment.location.name)),
  ].map((name) => ({
    id: name,
    state: name,
  }));

  const onSubmit = (value) => {
    setFilterData([]);

    if (!modifiedShipmentsData) return;

    let entity, status, fromDate, toDate;

    if (filterFormData) {
      entity = value.entity ? value.entity : filterFormData.entity;
      status = value.status ? value.status : filterFormData.status;
      fromDate = value.fromDate ? value.fromDate : filterFormData.fromDate;
      toDate = value.toDate ? value.toDate : filterFormData.toDate;
    } else {
      entity = value.entity;
      status = value.status;
      fromDate = value.fromDate;
      toDate = value.toDate;
    }

    const formattedFromDate = fromDate
      ? FORMATE_DATE(fromDate, 'mm/dd/yy')
      : null;
    const formattedToDate = toDate ? FORMATE_DATE(toDate, 'mm/dd/yy') : null;

    let filteredData = modifiedShipmentsData.filter((shipment) => {
      const isEntityMatch =
        !entity || shipment.entityName.toLowerCase() === entity.toLowerCase();
      const isStatusMatch =
        !status ||
        status.toLowerCase() === 'both' ||
        (status.toLowerCase() === 'delivered' && shipment.deliveredStatus) ||
        (status.toLowerCase() === 'in-transit' && !shipment.deliveredStatus);

      const isPickupDateMatch =
        !formattedFromDate || shipment.pickup >= formattedFromDate;
      const isDeliveryDateMatch =
        !formattedToDate || shipment.delivery <= formattedToDate;

      return (
        isEntityMatch &&
        isStatusMatch &&
        isPickupDateMatch &&
        isDeliveryDateMatch
      );
    });

    localStorage.setItem(
      'formFilter',
      JSON.stringify({
        entity: entity,
        status: status,
        fromDate: fromDate,
        toDate: toDate,
      })
    );

    if (filteredData.length === 0) {
      toast.dismiss();
      toast.error('Sorry No Data of Specific Filter/s', {
        duration: TOAST_TIME,
      });
    }

    setFilterData(filteredData);
    setShowFilterModal(false);
  };

  const onReset = () => {
    reset();
    setFilterData([]);
    closeFilterModal();
    localStorage.removeItem('formFilter');
  };

  // refund shipment
  const refundHandler = async (shipmentId) => {
    toast.dismiss();
    toast.loading('Cancelling the shipment...');
    const { data, errors } = await refundShipmentCall(shipmentId);
    if (data) {
      const response = await getAllShipmentsCall();
      setShipments(response?.data?.shipments);
      toast.dismiss();
      toast.success('Shipment cancelled successfully', {
        duration: TOAST_TIME,
      });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in canceling shipment', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  //  cancel shipments
  const cancelShipmentHandler = async (shipmentId) => {
    toast.dismiss();
    toast.loading('Deleting shipment...');
    const { data, errors } = await cancelShipmentCall(shipmentId);
    if (data) {
      const response = await getAllShipmentsCall();
      setShipments(response?.data?.shipments);
      toast.dismiss();
      toast.success('Shipment deleted successfully', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in canceling shipment', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  // delete confirmation modal

  const openRefundModal = () => setShowRefundModal(true);
  const closeRefundModal = () => setShowRefundModal(false);

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  // change status modal modal

  const openshipmentStatusModal = () => setShowShipmentStatusModal(true);
  const closeShipmentStatusModal = () => setShowShipmentStatusModal(false);

  // filter modal

  const openFilterModal = () => {
    setShowFilterModal(true);
  };

  const closeFilterModal = () => setShowFilterModal(false);

  //  =hhandle action menu dots click

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setShipmentActionClickedId(parseInt(data?.id));
    setShipmentActionClickedData(data);

    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
  };

  //

  const approveShipmentHandler = async (shipmentId, stausObject) => {
    const findByIDShipment = await shipments?.find(
      (shipment) => parseInt(shipment.id) === shipmentId
    );
    toast.dismiss();
    toast.loading('Changing shipment status...');
    const { data, errors } = await changeShipmentStatus({
      shipmentId,
      data: {
        ...findByIDShipment,
        approved: stausObject.approvedStatus,
        isDelivered: stausObject.deliveredStatus,
        isManuallyPaid: stausObject.isManuallyPaid,
      },
    });

    if (data) {
      const response = await getAllShipmentsCall();
      setShipments(response?.data?.shipments);
      toast.dismiss();

      toast.success('Shipment status changed successfully', {
        duration: TOAST_TIME,
      });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in changing shipment status', {
        duration: TOAST_TIME,
      });
    }

    return { data, errors };
  };

  //

  const confirmPaymentStatus = async (shipmentId) => {
    toast.dismiss();
    toast.loading('Generating confirmation Id...');
    const { data, errors } = await confirmPaymentStatusCall(shipmentId);
    if (data) {
      getAllShipmentsCall();
      toast.dismiss();
      toast.success('Shipment confirmed successfully', {
        duration: TOAST_TIME,
      });
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to change to paid', {
        duration: TOAST_TIME,
      });
    }

    return { data, errors };
  };

  //
  const changeConfirmStatus = async (shipmentId) => {
    if (shipmentId) {
      toast.dismiss();
      toast.loading('Generating confirmation Id...');
      const { data, errors } = await changeConfirmStatusCall(shipmentId);
      if (data) {
        getAllShipmentsCall();
        toast.dismiss();
        toast.success('Confirmation Id generated successfully', {
          duration: TOAST_TIME,
        });
      }
      if (errors) {
        toast.dismiss();
        toast.error('Failed to generate confirmation id', {
          duration: TOAST_TIME,
        });
      }

      return { data, errors };
    }
  };

  const reportShipmentHandler = () => {
    dispatch(resetReportShipmentFormAction());
    // if (Array.isArray(payments?.payments) && payments?.payments.length > 0) {
    navigate(PATHS.REPORTS_SHIPMENTS);
    // } else {
    //   toast.dismiss();
    //   toast.error('Please add a payment method', { duration: TOAST_TIME });
    // }
  };

  return {
    data: filterData.length > 0 ? filterData : modifiedShipmentsData,
    entityOptions: entityOptionData,
    refundHandler,
    cancelShipmentHandler,
    closeDeleteModal,
    shipmentActionClickedId,
    actionMenuRef,
    toggleActionMenu,
    shipmentActionClickedData,
    openRefundModal,
    closeRefundModal,
    showRefundModal,
    openDeleteModal,
    handleActionClick,
    showDeleteModal,
    showShipmentStatusModal,
    openshipmentStatusModal,
    closeShipmentStatusModal,
    showFilterModal,
    setShowFilterModal,
    openFilterModal,
    closeFilterModal,
    approveShipmentHandler,
    changeConfirmStatus,
    confirmPaymentStatus,
    register,
    handleSubmit,
    onSubmit,
    errors,
    setValue,
    onReset,
    handleViewAttachments,
    open,
    handleClose,
    attachments,
    loading,
    user,
    reportShipmentHandler,
    loading,
  };
};
