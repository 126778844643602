import React, { useEffect, useState } from 'react';
import { CustomDropdown, CustomInput } from '../../../../common/custom';
import TextArea from '../../../../common/custom/customTextArea';
import UploadClaimCloseDoc from './claim-close-doc.component';
import FileUpload from '../../../../common/custom/file-upload/file-upload.component';
import CircularILoader from '../../../../common/custom/circularLoader';
import FilePreview from '../../../../common/custom/file-preview/file-preview';
import {
  extractArrayData,
  FILE_TYPE,
  HANDLE_FILE_SIZE_CHECK,
} from '../../../../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../../hook/useApi';
import {
  updateClaimMutation,
  updateManualClaimMutation,
} from '../../../../api/services/claim.service';
import { PATHS } from '../../../../constants/paths';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import toast from 'react-hot-toast';
import axios from 'axios';

function UpdateClaim({ claim, register, errors, options, onCancel }) {
  const navigate = useNavigate();

  const [updateClaim] = useApi(updateClaimMutation);
  const [updateManualClaim] = useApi(updateManualClaimMutation);

  const [claimType, setClaimType] = useState('');
  const [adjustmentAmount, setAdjustmentAmount] = useState(0);
  const [claimClosefile, setClaimCloseFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  const [bestWayToContact, setBestWayToContact] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [customerNote, setCustomerNote] = useState();
  const [description, setDescription] = useState();
  const [invoice, setInvoice] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [receipt, setReceipt] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState();
  const [attachmentLoading, setAttachmentLoading] = useState();
  const [receiptLoading, setReceiptLoading] = useState();

  useEffect(() => {
    if (claim) {
      setClaimType(claim?.status);
      setAdjustmentAmount(
        claim?.claimAmount ? claim?.claimAmount : claim?.adjustedAmount
      );
      setBestWayToContact(claim.bestWayToContact);
      setCustomerEmail(claim.customerEmail);
      setCustomerPhone(claim.customerPhone);
      setCustomerNote(claim.customerNote);
      setDescription(claim.description);
      setInvoice(extractArrayData(claim.invoice));
      setAttachment(extractArrayData(claim.attachments));
      setReceipt(extractArrayData(claim.receipts));
    }
  }, [claim]);

  const handleInvoiceUpload = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setInvoiceLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setInvoice((prevFiles) => [...prevFiles, ...successfulUploads]);
          setInvoiceLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setInvoiceLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handleAttchmentUpload = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setAttachmentLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setAttachment((prevFiles) => [...prevFiles, ...successfulUploads]);
          setAttachmentLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setAttachmentLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handleReceiptUpload = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setReceiptLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setReceipt((prevFiles) => [...prevFiles, ...successfulUploads]);
          setReceiptLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setReceiptLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handleInvoiceCancel = (index) => {
    setInvoice((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleAttachmentCancel = (index) => {
    setAttachment((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleReceiptCancel = (index) => {
    setReceipt((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpdateClaim = async (e) => {
    e.preventDefault();

    const mutation = claim?.claimAmount
      ? updateClaim
      : claim?.adjustedAmount
        ? updateManualClaim
        : null;

    if (mutation) {
      const { data } = await mutation({
        claimId: Number(claim?.id),
        claimAmount: adjustmentAmount,
        status: claimType,
        internalNote: claim.internalNote,
        claimCloseDoc: JSON.stringify(claimClosefile),
        bestWayToContact,
        customerEmail,
        customerPhone,
        customerNote,
        description,
        receipts: JSON.stringify(receipt),
        invoice: JSON.stringify(invoice),
        attachments: JSON.stringify(attachment),
      });
      if (data) {
        navigate(PATHS.CLAIMS);
        toast.success('The Claim updated successfully', {
          duration: TOAST_TIME,
        });
      } else {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    }
  };

  return (
    <form
      className='flex flex-col justify-center gap-8 self-stretch border shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl border-solid border-[#EAECF0]'
    // onSubmit={handleSubmit(handleUpdateClaim)}
    >
      <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
        Update Claim
      </div>
      {console.log('claim type', claimType)}
      <div className='flex flex-col w-full'>
        <CustomDropdown
          placeholder='Claim Type'
          label='Claim Status'
          name='claimType'
          value={claimType}
          errors={errors}
          options={options}
          onChange={(value) => setClaimType(value.id)}
        />
        <div className='mt-4'>
          <CustomInput
            placeholder='Adjustment Amount'
            label='Adjustment Amount'
            // name='adjustmentAmount'
            value={adjustmentAmount}
            onChange={(e) => setAdjustmentAmount(e?.target?.value)}
          />
        </div>
        {/* <TextArea
          type='text'
          name='internalNote'
          register={register}
          errors={errors}
          label='Internal Note'
          placeholder='Internal note'
          labelClassName='mt-6 text-[#344054] text-sm not-italic font-medium leading-5'
        /> */}
        <div className='mt-4'>
          <CustomInput
            type='text'
            name='internalNote'
            register={register}
            errors={errors}
            label='Internal Note'
            placeholder='Internal note'
            labelClassName='mt-6 text-[#344054] text-sm not-italic font-medium leading-5'
          />
        </div>
        <UploadClaimCloseDoc
          files={claim?.claimCloseDoc ? JSON.parse(claim?.claimCloseDoc) : null}
          file={claimClosefile}
          setFile={setClaimCloseFile}
          fileError={fileError}
          setFileError={setFileError}
        />
      </div>

      {/* <div className='mt-4'> */}
      {/* <CustomDropdown
        placeholder='Best way to contact'
        label='Best Way to contact the Recipient'
        value={bestWayToContact}
        options={[
          { id: 'Email', state: 'Email' },
          { id: 'Phone', state: 'Phone' },
        ]}
        setValue={(val) => setBestWayToContact(val)}
      /> */}
      {/* </div> */}
      {/* <div className='mt-4'> */}
      {/* <CustomInput
        readOnly={false}
        placeholder='Customer Email'
        label='Customer Email'
        value={customerEmail}
        onChange={(e) => setCustomerEmail(e?.target?.value)}
      /> */}
      {/* </div> */}
      {/* <div className='mt-4'> */}
      {/* <CustomInput
        readOnly={false}
        placeholder='Customer Phone'
        label='Customer Phone'
        value={customerPhone}
        onChange={(e) => setCustomerPhone(e?.target?.value)}
      /> */}
      {/* </div> */}
      {/* <TextArea
        readOnly={false}
        type='text'
        name='customerNote'
        label='Customer Note'
        labelClassName='mt-6 text-[#344054] text-sm not-italic font-medium leading-5'
        value={customerNote}
        onChange={(e) => setCustomerNote(e.target.value)}
      />
      <TextArea
        readOnly={false}
        type='text'
        name='description'
        label='Description'
        labelClassName='mt-6 text-[#344054] text-sm not-italic font-medium leading-5'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      /> */}
      {/* <div className=''>
        <div className='my-2'> */}
      {/* {claim?.status === 'Claim Received' && (
        <>
          <span className='font-bold'>Invoices:</span>
          {invoice && invoiceLoading ? (
            <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
              <CircularILoader />{' '}
            </div>
          ) : invoice?.length > 0 ? (
            <>
              <FilePreview files={invoice} handleCancel={handleInvoiceCancel} />
            </>
          ) : (
            <>
              <FileUpload
                fileTypes={FILE_TYPE}
                multiple={true}
                handleChange={handleInvoiceUpload}
              />
            </>
          )}
        </>
      )} */}
      {/* </div>
      </div> */}

      {/* <div className=''>
        <div className='my-2'> */}
      {/* {claim?.status === 'Claim Received' && (
        <>
          <span className='font-bold'>Attachment:</span>
          {attachment && attachmentLoading ? (
            <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
              <CircularILoader />{' '}
            </div>
          ) : attachment?.length > 0 ? (
            <>
              <FilePreview
                files={attachment}
                handleCancel={handleAttachmentCancel}
              />
            </>
          ) : (
            <>
              <FileUpload
                fileTypes={FILE_TYPE}
                multiple={true}
                handleChange={handleAttchmentUpload}
              />
            </>
          )}
        </>
      )} */}
      {/* </div>
      </div> */}
      {/* <div className=''>
        <div className='my-2'> */}
      {/* {claim?.status === 'Claim Received' && (
        <>
          <span className='font-bold'>Receipts:</span>
          {receipt && receiptLoading ? (
            <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
              <CircularILoader />{' '}
            </div>
          ) : receipt?.length > 0 ? (
            <>
              <FilePreview files={receipt} handleCancel={handleReceiptCancel} />
            </>
          ) : (
            <>
              <FileUpload
                fileTypes={FILE_TYPE}
                multiple={true}
                handleChange={handleReceiptUpload}
              />
            </>
          )}
        </>
      )} */}
      {/* </div>
      </div> */}

      <div className='flex justify-end gap-4'>
        <button
          type='button'
          className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
          onClick={onCancel}
        >
          Cancel
        </button>

        <button
          className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
          type='submit'
          onClick={handleUpdateClaim}
        >
          Update Claim
        </button>
      </div>
    </form>
  );
}

export default UpdateClaim;
