import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getClaimDetailsQuery,
  updateClaimMutation,
} from '../../../api/services/claim.service';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { updateClaimSchema } from '../../shipments/schema/describeClaimSchema';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';

function UseClaimDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateClaim] = useApi(updateClaimMutation);

  const { handleSubmit, register, errors, setValue } =
    useHookFormSubmission(updateClaimSchema);

  const [singleClaimDetails, data] = useApi(getClaimDetailsQuery);

  const [claimType, setClaimType] = useState('');
  const [adjustmentAmount, setAdjustmentAmount] = useState(0);
  const [claimData, setClaimData] = useState(null);
  const [claimClosefile, setClaimCloseFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    claimDetail();
  }, [id]);

  const claimDetail = async () => {
    const singleClaim = await singleClaimDetails(Number(id));
    setClaimData(singleClaim?.data?.claim);
    setClaimType(
      singleClaim && singleClaim?.data?.claim && singleClaim?.data?.claim?.status
    );
    setAdjustmentAmount(
      singleClaim &&
      singleClaim.data.claim &&
      singleClaim.data.claim.claimAmount
    );
    setValue(
      'claimType',
      claimType ||
      (singleClaim && singleClaim.data.claim && singleClaim.data.claim.status)
    );
    setValue(
      'internalNote',
      singleClaim &&
      singleClaim.data.claim &&
      singleClaim.data.claim.internalNote
    );
  };

  const options = [
    { id: 'Claim Received', state: 'Claim Received' },
    { id: 'Under Investigation', state: 'Under Investigation' },
    { id: 'Claim Assessed', state: 'Claim Assessed' },
    { id: 'Pending Documentation', state: 'Pending Documentation' },
    { id: 'Claim Approved', state: 'Claim Approved' },
    { id: 'Claim Denied', state: 'Claim Denied' },
    { id: 'Settlement Offered', state: 'Settlement Offered' },
    { id: 'Claim Paid and Closed', state: 'Claim Paid and Closed' },
    { id: 'Claim Closed', state: 'Claim Closed' },
  ];

  const handleUpdateClaim = async (values) => {
    // console.log(values, claimClosefile);
    // return;
    toast.loading('Please wait! Updating the claim...', {
      duration: TOAST_TIME,
    });
    const { data, errors } = await updateClaim({
      claimId: Number(id),
      claimAmount: adjustmentAmount,
      status: claimType,
      internalNote: values.internalNote,
      claimCloseDoc: JSON.stringify([claimClosefile]),
    });

    if (data) {
      toast.success('Claim updated successfully!', { duration: TOAST_TIME });
      navigate(-1);
    } else if (errors) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    }
  };

  const onCancel = () => navigate(-1);

  return {
    claim: data && data.claim,
    claimType,
    options,
    setClaimType,
    handleSubmit,
    register,
    errors,
    handleUpdateClaim,
    claimData,
    onCancel,
    claimClosefile,
    setClaimCloseFile,
    fileError,
    setFileError,
    adjustmentAmount,
    setAdjustmentAmount,
  };
}

export default UseClaimDetails;
